import React from 'react'

const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  } else if ((/iPad|iPhone|iPod/.test(ua)) ||
     (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS'
  }
  return 'Other'
}

export default function ({ playStoreLink, appStoreLink }) {
  React.useEffect(() => {
    const os = getMobileOS()

    if (os === 'Android') {
      window.location.replace(playStoreLink)
    } else if (os === 'iOS') {
      window.location.replace(appStoreLink)
    }
  })

  return (<div />)
}

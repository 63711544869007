import { German } from 'flatpickr/dist/l10n/de'
import 'bootstrap'
import flatpickr from 'flatpickr'
import './obs_style'
import ReactOnRails from 'react-on-rails'

import $ from 'jquery'

import PayPalWarning from '../src/PayPalWarning'
import AppRedirect from '../src/AppRedirect'

window.htmx = require('htmx.org')

window.$ = $
window.jQuery = $

$(document).ready(() => {
  $('.product-col-clickable').click(function (event) {
    event.stopImmediatePropagation()
    window.location = $(this).find('.product-link').attr('href')
  })

  window.disabled_products.forEach((id) => {
    $('#product-' + id).hide()
  })

  document.querySelectorAll('form').forEach((form) => {
    form.addEventListener('submit', (e) => {
      // Prevent if already submitting
      if (form.classList.contains('is-submitting')) {
        e.preventDefault()
      }

      // Add class to hook our visual indicator on
      form.classList.add('is-submitting')
    })
  })

  $('.obs-day-selector').each((_, element) => {
    const enabledDates = JSON.parse(element.dataset.enableDates)
    const config = {
      enable: enabledDates,
      onChange: function (selectedDates, dateStr, instance) {
        element.value = dateStr
        element.dispatchEvent(new Event('search'))
      },
      dateFormat: 'd.m.Y',
      locale: German
    }

    flatpickr(element, config)
  })
})

ReactOnRails.register({
  PayPalWarning,
  AppRedirect
})
